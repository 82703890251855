<template>
  <v-container fluid style="background-color: #fff" class="pa-0">
    <Selecionar :selecionar="selecionar">
      <v-select
        dense
        hide-details
        :items="leiloes"
        item-text="nome"
        v-model="leilaoSelecionado"
        return-object
        style="max-width: 400px"
        background-color="#ffffff"
        single-line
        @change="changeLeilao"
        label="Selecione um Leilão"
        outlined
      >
        <template v-slot:item="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.id }} - {{ item.nome }} - {{ item.titulo }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item }">
          {{ item.id }} - {{ item.nome }} - {{ item.titulo }}
        </template>
      </v-select>
    </Selecionar>

    <v-row>
      <v-col cols="12" md="8" lg="8" xl="8" class="py-5">
        <v-card elevation="0" rounded="lg">
          <v-card-text class="px-2">
            <v-row justify="space-around" class="ma-0">
              <div class="card-info">
                <p class="text--secondary">LOTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao.numeroLote }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">ÚLTIMO LANCE</p>
                <v-sheet height="20" width="100" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    width="100"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  R$ {{ emLeilao && emLeilao.info.ultimoLance | formatMoeda }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">ARREMATANTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao && emLeilao.info.usuarioUltimoLance }}
                </p>
              </div>
              <div class="card-info hidden-sm-and-down">
                <p class="text--secondary">STATUS</p>
                <p
                  class="font-weight-black text-right"
                  :style="
                    emLeilao && emLeilao.info.emFechamento
                      ? countStatus === 'Homologando...'
                        ? 'color: #f9c718 !important'
                        : 'color: #e0280f !important'
                      : 'color: #bbbbbb'
                  "
                >
                  <span
                    v-if="
                      emLeilao &&
                      emLeilao.info &&
                      (emLeilao.info.status.includes('Encerrado') ||
                        emLeilao.info.status.includes('Fechamento') ||
                        emLeilao.info.status.includes('Aguardando') ||
                        emLeilao.info.status.includes('Aberto')) &&
                      !countStatus
                    "
                    >{{ emLeilao.info.status }}</span
                  >
                  <span v-else-if="emLeilao && countStatus">{{
                    countStatus
                  }}</span>
                </p>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" lg="8" xl="8" class="pl-0">
                <v-card
                  rounded="lg"
                  class="clicavel"
                  style="position: relative"
                >
                  <v-carousel
                    hide-delimiters
                    height="400"
                    cycle
                    interval="5000"
                  >
                    <v-carousel-item v-if="loading">
                      <v-sheet class="pa-3">
                        <v-skeleton-loader
                          class="mx-auto"
                          type="image"
                        ></v-skeleton-loader>
                      </v-sheet>
                    </v-carousel-item>
                    <v-carousel-item
                      v-else
                      v-for="(foto, i) in emLeilao.fotos"
                      :key="i"
                      :src="foto && foto.arquivo.url"
                      @click="zoom(foto && foto.arquivo.url, i)"
                    >
                      <span class="tag-leilao">
                        {{ emLeilao && emLeilao.descricao }}
                      </span>
                    </v-carousel-item>
                  </v-carousel>
                </v-card>

                <!-- <v-row class="mt-2 hidden-sm-and-down" v-if="loading">
                  <v-col cols="6" md="4" lg="4" xl="4" v-for="i in 3" :key="i">
                    <v-card>
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-skeleton-loader class="mx-auto" max-width="250" max-height="80"
                          type="image"></v-skeleton-loader>
                      </v-responsive>
                    </v-card>
                  </v-col>
                </v-row> -->
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4" class="caracteristicas">
                <v-sheet elevation="2" height="100%" rounded="lg">
                  <v-row align="center" justify="center">
                    <v-col align="end" cols="5">
                      <img
                        :src="require('@/assets/icon1.png')"
                        style="width: 45px"
                      />
                    </v-col>
                    <v-col>
                      <p class="font-weight-black text-h4">
                        {{ emLeilao && emLeilao.info.qtdParticipantes }}
                      </p>
                      <p class="font-secondary">Participantes</p>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col align="end" cols="5">
                      <img
                        :src="require('@/assets/icon2.png')"
                        style="width: 45px"
                      />
                    </v-col>
                    <v-col>
                      <p class="font-weight-black text-h4">
                        {{ emLeilao && emLeilao.info.qtdVisualizacoes }}
                      </p>
                      <p class="font-secondary">Visualizações</p>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col align="end" cols="5">
                      <img
                        :src="require('@/assets/icon3.png')"
                        style="width: 45px"
                      />
                    </v-col>
                    <v-col>
                      <p class="font-weight-black text-h4">
                        {{ emLeilao && emLeilao.info.qtdLances }}
                      </p>
                      <p class="font-secondary">Lances</p>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <img src="@/assets/logo-vertical.jpeg" class="logo" />
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        class="py-5"
        style="display: flex; align-items: center; justify-content: center"
      >
        <qr-code :text="getLinkLote" :size="200" error-level="L"></qr-code>
      </v-col>
    </v-row>
    <v-dialog v-model="zoomImg" max-width="720" max-height="360" v-if="zoomImg">
      <v-carousel v-model="itemCarosel">
        <v-carousel-item
          v-for="(foto, i) in emLeilao.fotos"
          :key="i"
          :src="foto && foto.arquivo.url"
          aspect-ratio="2"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>

    <count-down
      v-show="false"
      :final="emLeilao && emLeilao.info.dataEncerramento"
      :atual="emLeilao && emLeilao.info.dataAtualUtc"
      @FinishCountdown="onFinishCountDown"
    />
  </v-container>
</template>

<script>
import Leilao from '@/service/leilao';
// import CardLote from "../components/card-lote.vue";
import CountDown from '../components/CountDown.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import sweetalertMixins from '../../mixins/sweetalertMixin';
import Selecionar from './selecionar.vue';
import { ORIGIN } from '@/config';

export default {
  name: 'PainelYoutube',
  components: {
    // CardLote,
    CountDown,
    Selecionar,
  },
  mixins: [sweetalertMixins],
  data() {
    return {
      zoomImg: false,
      urlImg: '',
      itemCarosel: 0,
      contadorLote: {
        total: 0,
      },
      contadores: {
        lances: 0,
      },
      leiloes: [],
      leilaoSelecionado: {},
      lotes: null,
      leiloados: null,
      emLeilao: null,
      proximosLotes: [],
      lotesLeiloados: [],
      loadingProximosLotes: false,
      loadingLotesLeiloados: false,
      countStatus: '',
      loading: true,
      mostrarIniciar: false,
      encerrando: false,
      totalLeiloados: 0,
      totalAndamento: 0,
    };
  },

  computed: {
    ...mapGetters(['getAcao', 'getAcaoLoteAtivo', 'getLeilao']),
    widownHeight() {
      return window.innerHeight * 0.9;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tempoRestante() {
      const tempo = this.leilaoSelecionado.tempoInicioSeg;
      const format = tempo >= 60 ? 'm[m] : s[s]' : 's[s]';
      return moment('00:00', 'mm:ss').add(tempo, 's').format(format);
    },
    selecionar() {
      return !this.leilaoSelecionado.nome;
    },
    getLinkLote() {
      return (this.emLeilao && ORIGIN + '/lote/' + this.emLeilao.loteId) || '';
    },
  },

  methods: {
    ...mapActions(['setAuth', 'iniciar', 'parar', 'encerrar', 'getLoteInfo']),
    ...mapMutations(['setTituloPagina']),

    zoom(url, index) {
      this.urlImg = url;
      this.itemCarosel = parseInt(index);
      this.zoomImg = true;
    },
    mostrarLote(lote) {
      this.countStatus = null;
      this.$nextTick(() => this.setEmleilao(lote));
    },
    async encerra() {
      try {
        await this.encerrar(this.emLeilao.loteId);
      } catch (error) {
        this.showToast({ title: 'Aguardando encerramento' });
      }
      this.encerrando = false;
    },
    async acaoAoEncerrar() {
      if (this.proximosLotes.length > 1) {
        this.setEmleilao(this.proximosLotes[1]);
      }
      const { id } = this.leilaoSelecionado;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.proximosLotes = lotes.data;
      const leiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });

      this.lotesLeiloados = leiloados.data;
      this.totalLeiloados = leiloados.totalRecords;
      this.totalAndamento = lotes.totalRecords;

      this.encerrando = false;
    },
    async addLotes(id) {
      let page = this.lotes.pageNumber + 1;

      if (page > 1 && this.lotes && page > this.lotes.totalPages) return;

      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      lotes.data.forEach((e) => this.proximosLotes.push(e));

      this.loadingProximosLotes = false;
    },
    async addLotesLeiloados(id) {
      const page = this.leiloados.pageNumber + 1;

      if (page > 1 && this.leiloados && page > this.leiloados.totalPages)
        return;

      this.loadingLotesLeiloados = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'encerrado' });
      this.leiloados = lotes;
      this.totalLeiloados = lotes.totalRecords;

      lotes.data.forEach((e) => this.lotesLeiloados.push(e));

      this.loadingLotesLeiloados = false;
    },
    async onChangeLeilao() {
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.lotes = null;
      await this.addLotes(id);
      this.setEmleilao();
      await this.addLotesLeiloados(id);
    },
    async changeLeilao() {
      this.lotes = null;
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.onJoinGroup(id);
      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      this.proximosLotes = lotes.data;
      this.loadingProximosLotes = false;

      this.setEmleilao();

      this.loadingLotesLeiloados = true;
      const lotesLeiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });
      //this.leiloados = lotes;
      this.totalLeiloados = lotesLeiloados.totalRecords;
      this.lotesLeiloados = lotesLeiloados.data;
      this.loadingLotesLeiloados = false;
    },
    async onFinishCountDown(finalizar) {
      if (finalizar) {
        this.countStatus = 'Homologando...';
      }
    },
    timeout() {
      return new Promise((resolve) => {
        setTimeout(resolve, 200);
      });
    },
    async setEmleilao(item = null) {
      this.loading = true;
      this.countStatus = null;
      if (!item) {
        item =
          this.proximosLotes.find((e) => e.info.emFechamento) ||
          this.proximosLotes[0];
      }
      const { data } = await Leilao.getLote({ id: item.loteId });
      const resp = await Leilao.getLoteInfo({ id: item.loteId });
      this.emLeilao = data;
      this.emLeilao['info'] = { ...resp.data };
      this.emLeilao['fotos'] = {
        ...data.fotos.filter((e) => e.tipoFoto.visivelSite),
      };

      this.loading = false;
      this.countStatus = '';
      return;
    },

    async inicializa() {
      try {
        const leiloes = await Leilao.getLeiloesAberto();

        this.leiloes = leiloes.data;

        if (this.getLeilao) {
          this.leilaoSelecionado = { ...this.getLeilao };
          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
          return;
        }

        if (this.leiloes.length == 1) {
          this.leilaoSelecionado = this.leiloes[0];

          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
        }
      } catch (_) {
        //console.error();
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtual');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotes(id);
        return;
      }
      this.toTop = false;
    },
    onScrollLeiloados(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtualLeiloados');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotesLeiloados(id);
        return;
      }
      this.toTop = false;
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },

    async onEventNewLance(loteinfo) {
      if (this.emLeilao.loteId === loteinfo.loteId) {
        this.countStatus = null;

        if (loteinfo.status === 'Encerrado') {
          this.acaoAoEncerrar();
        }

        this.emLeilao['info'] = { ...loteinfo };
        this.countStatus = '';
      }

      const lote = this.proximosLotes.find((e) => e.loteId == loteinfo.loteId);
      if (lote) {
        lote.info = { ...loteinfo };
      }
    },
  },
  beforeDestroy() {
    this.$signalR.$off('new-lance', this.onEventNewLance);
  },
  filters: {
    formataData(val) {
      if (!val) return '';
      return new Date(val).toLocaleString();
    },
    formataDataHora(val) {
      if (!val) return '';
      return moment(val).utc().local().format('DD/MM/YYYY HH:mm');
    },
    formatMoeda(val) {
      if (!val) return '';
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    },
  },
  async created() {
    this.setTituloPagina('PAINEL YOUTUBE');
    await this.inicializa();
    this.$signalR.$on('new-lance', this.onEventNewLance);
  },
};
</script>

<style>
p {
  margin: 0px !important;
}

.v-carousel {
  min-width: 513.33px !important;
}

.tag-leilao {
  position: absolute;
  z-index: 9999;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #222;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  letter-spacing: 1.5px;
}

.virtual-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.virtual-scroll::-webkit-scrollbar {
  display: none;
}

.card-info div {
  display: flex;
  justify-content: flex-end;
}

.card-info .text--secondary {
  font-size: 13px;
}

.card-info .font-weight-black {
  font-size: 20px;
}

.caracteristicas span {
  font-size: 11px;
}

.clicavel {
  cursor: pointer;
}

.painel {
  font-size: 13px;
}

.mobile .text--secondary {
  font-size: 11px;
}

iframe {
  width: 100%;
  height: 100%;
}

.youtube {
  width: 320px;
  height: 180px;
  border: 3px solid #fff;
  box-shadow: 0px 7px 5px 0px #00000060;
}

.logo {
  max-width: 150px;
  padding-top: 15px;
}

.tag-leilao {
  position: absolute;
  z-index: 9999;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #222;
  font-weight: bold;
  border-bottom-right-radius: 10px;
  letter-spacing: 1.5px;
}
</style>
